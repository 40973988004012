import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Clock,
  Eye,
  EyeSlash,
  InfoCircle,
  MapMarkerAlt,
  Mobile,
  PlusCircle,
  Store,
  TimesCircle,
  Trash,
  User,
  YoutubePlay,
} from 'icons';
import { cn } from 'utils/cn';
import type { Custom as CustomIconLookup, IconLookup } from 'utils/fontAwesome';

export type IconProps = React.HtmlHTMLAttributes<SVGSVGElement> & {
  className?: string;
  icon: IconLookup;
};

const customIcons: Record<CustomIconLookup['name'], React.VFC<React.SVGProps<SVGSVGElement>>> = {
  clock: Clock,
  eye: Eye,
  'eye-slash': EyeSlash,
  'info-circle': InfoCircle,
  'map-marker-alt': MapMarkerAlt,
  'phone-rotary': Mobile,
  'plus-circle': PlusCircle,
  store: Store,
  'times-circle': TimesCircle,
  trash: Trash,
  user: User,
  'youtube-play': YoutubePlay,
};

const Icon = ({ className, icon, ...props }: IconProps) => {
  // TODO: Revise if we want to have an approach like this for custom icons or not
  if (icon.styling === 'custom') {
    const CustomIcon = customIcons[icon.name];
    return <CustomIcon className={cn('size-4', className)} />;
  }

  return (
    <FontAwesomeIcon
      icon={{ iconName: icon.name, prefix: icon.styling }}
      color="currentColor"
      className={cn('size-4 shrink-0', className)}
      {...props}
    />
  );
};

export { Icon };
