/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as React from 'react';
import { tv, VariantProps } from 'tailwind-variants';
import { cn } from 'utils/cn';
import { Icon, IconProps } from '../Icon/Icon';
import { LinkProps, TextLink } from '../Link/Link';
import { Text, TextProps, textStyling } from '../Text/Text';

// TODO: rework compound variants to not use outlined, outlined should be a separate variant
export const buttonVariants = tv({
  base: `border text-foreground gap-2 inline-flex items-center justify-center whitespace-nowrap border-offset-background transition-colors px-6 py-2.5 rounded-full focus-visible:outline-none disabled:pointer-events-none`,
  defaultVariants: {
    variant: 'primary',
  },
  variants: {
    hover: {
      false: '',
      true: '',
    },
    variant: {
      primary:
        'font-medium bg-button-primary-default-background text-button-primary-default-foreground border-button-primary-default-border hover:bg-button-primary-hover-background hover:text-button-primary-hover-foreground hover:border-button-primary-hover-border disabled:bg-button-primary-disabled-background disabled:border-button-primary-disabled-border disabled:text-button-primary-disabled-foreground',
      secondary:
        'bg-button-secondary-default-background text-button-secondary-default-foreground border-button-secondary-default-border hover:bg-button-secondary-hover-background hover:text-button-secondary-hover-foreground hover:border-button-secondary-hover-border disabled:bg-button-secondary-disabled-background disabled:border-button-secondary-disabled-border disabled:text-button-secondary-disabled-foreground',
      tertiary:
        'bg-button-tertiary-default-background text-button-tertiary-default-foreground border-button-tertiary-default-border hover:bg-button-tertiary-hover-background hover:text-button-tertiary-hover-foreground hover:border-button-tertiary-hover-border disabled:bg-button-tertiary-disabled-background disabled:border-button-tertiary-disabled-border disabled:text-button-tertiary-disabled-foreground',
      outlined:
        'bg-button-outlined-default-background text-button-outlined-default-foreground border-button-outlined-default-border hover:bg-button-outlined-hover-background hover:text-button-outlined-hover-foreground hover:border-button-outlined-hover-border disabled:bg-button-outlined-disabled-background disabled:border-button-outlined-disabled-border disabled:text-button-outlined-disabled-foreground',
      ghost:
        'border-0 text-button-ghost-default-foreground hover:text-button-ghost-hover-foreground focus-visible:px-1',
      naked: 'p-0 border-0 font-normal text-link-base hover:text-link-hover',
    },
  },
});

export type ButtonProps = React.InputHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
  href?: LinkProps['href'];
  icon?: IconProps['icon'];
  iconClassName?: string;
  iconPosition?: 'before' | 'after';
  loading?: boolean;
  locale?: string;
  newTab?: boolean;
  outlined?: boolean;
  textType?: TextProps['type'];
  type?: 'button' | 'reset' | 'submit' | undefined;
  variant?: VariantProps<typeof buttonVariants>['variant'];
};

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      href,
      icon,
      iconClassName,
      iconPosition = 'before',
      loading,
      locale,
      newTab,
      onClick,
      textType = 'body',
      type = 'button',
      variant,
      ...props
    },
    ref,
  ) => {
    const loadingComponent = (
      <Icon className={cn('h-4 w-4 animate-spin', iconClassName)} icon={{ name: 'spinner-third', styling: 'far' }} />
    );
    const iconComponent = !!icon && <Icon className={cn('h-4 w-4', iconClassName)} icon={icon} />;
    const classNameProp = buttonVariants({ className, hover: !!onClick || !!href, variant });

    const content = (
      <>
        {iconPosition === 'before' && <>{loading ? loadingComponent : iconComponent}</>}
        {children}
        {iconPosition === 'after' && <>{loading ? loadingComponent : iconComponent}</>}
      </>
    );

    if (href) {
      return (
        <TextLink
          newTab={newTab}
          className={classNameProp}
          href={href}
          locale={locale}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          type={textType}
        >
          {content}
        </TextLink>
      );
    }

    if (onClick || type === 'submit') {
      return (
        <button
          {...props}
          onClick={onClick}
          disabled={disabled || loading}
          className={cn(textStyling({ className: classNameProp, type: textType }))}
          ref={ref as React.ForwardedRef<HTMLButtonElement>}
          type={type}
        >
          {content}
        </button>
      );
    }

    return (
      <Text tag="div" type={textType} className={classNameProp} ref={ref as React.ForwardedRef<HTMLDivElement>}>
        {content}
      </Text>
    );
  },
);

const buttonIconVariants = tv({
  base: 'flex size-8 p-0 items-center justify-center rounded-full border border-transparent bg-accent-40 fill-accent-100 hover:border-accent-60',
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    variant: {
      default: 'bg-accent-40 fill-accent-100 hover:bg-accent-60',
      ghost: 'bg-transparent',
    },
  },
});

type ButtonIconProps = ButtonProps & {
  icon: IconProps['icon'];
  iconClassName?: string;
  label?: string;
  variant?: 'default' | 'ghost';
};

const IconButton = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ className, icon, iconClassName, label, type = 'button', variant, ...props }, ref) => (
    <button
      className={cn(
        buttonIconVariants({ className, variant }),
        'focus-ring disabled:accent-20 disabled:opacity-50 disabled:hover:border-0',
      )}
      ref={ref}
      type={type}
      {...props}
    >
      <Icon icon={icon} className={iconClassName} />
      <span className="sr-only">{label}</span>
    </button>
  ),
);

IconButton.displayName = 'IconButton';

export { Button, IconButton };
